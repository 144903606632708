import React from "react";

interface HeadingProps {
  level?: number;
  parallax?: boolean;
}

export const Heading: React.FunctionComponent<HeadingProps> = (
    {
      level = 1,
      parallax = true,
      ...rest
    }) => {

  const CustomTag = `h${level}` as keyof JSX.IntrinsicElements;
  return (
      <CustomTag>{rest.children}</CustomTag>
  )
}
